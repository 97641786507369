import * as React from 'react';
import './DemoGallery.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddBox';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';

const FAT_UNITS = "G";
const CHOLESTEROL_UNITS = "mg";
const CARBOHYDRATE_UNITS = "g";
const PROTEIN_UNITS = "g";
const VITAMIN_A_UNITS = "mcg";
const CALCIUM_UNITS = "mcg";
const IRON_UNITS = "mg";
const POTASSIUM_UNITS = "mg";
const DV_SYMBOL = "%" // probably overkill but ％﹪٪ exist so... 


function createData(
  name,
  url,
  json_url,
  serving_size,
  servings_per_container,
  calories_val,  
  total_fat_val,
  total_fat_dv,
  sat_fat_val,
  sat_fat_dv,
  trans_fat_val,
  cholesterol_val,
  cholesterol_dv,
  sodium_val,
  sodium_dv,
  total_carbohydrate_val,
  total_carbohydrate_dv,
  dietary_fiber_val,
  dietary_fiber_dv,
  total_sugars_val,
  added_sugars_val,
  added_sugars_dv,
  protein_val,  
  protein_dv,
  vitamin_d_val,
  vitamin_d_dv,
  calcium_val,
  calcium_dv,
  iron_val,
  iron_dv,
  potassium_val,
  potassium_dv,
  ingredients
) {
  return { 
    name,
    url,
    json_url,
    serving_size,
    servings_per_container,
    calories_val,    
    total_fat_val,
    total_fat_dv,
    sat_fat_val,
    sat_fat_dv,
    trans_fat_val,
    cholesterol_val,
    cholesterol_dv,
    sodium_val,
    sodium_dv,
    total_carbohydrate_val,
    total_carbohydrate_dv,
    dietary_fiber_val,
    dietary_fiber_dv,
    total_sugars_val,
    added_sugars_val,
    added_sugars_dv,
    protein_val,    
    protein_dv,
    vitamin_d_val,
    vitamin_d_dv,
    calcium_val,
    calcium_dv,
    iron_val,
    iron_dv,
    potassium_val,
    potassium_dv,
    ingredients
  };
}

export default function SelectedListItem() {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [imageDatas, setImageDatas] = React.useState([    
    createData('Soy Milk',"static/images/image5.jfif","static/json/image5.json","1 cup (240ml)",8,60,"2.5 g",3,"0 g",0,"0 g","0 mg",0,"150 mg",7,"8 g",3,"0 g",0,"7 g","7 g",14,"1 g","","2.5 mcg",10,"450 mg",30,"0.5 mg",2,"0 mg",0,"ALMONDMILK (FILTERED WATER, ALMONDS), CANE SUGAR, VITAMIN AND MINERAL BLEND (CALCIUM CARBONATE, VITAMIN E ACETATE, VITAMIN A PALMITATE, VITAMIN D2), SEA SALT, GELLAN GUM, SUNFLOWER LECITHIN, LOCUST BEAN GUM, ASCORBIC ACID (TO PROTECT FRESHNESS), NATURAL FLAVOR."),    
    createData('Guacamole',"static/images/image7.JPEG","static/json/image7.json","1 mini cup (57g)",6,120,"10 g",13,"2 g",10,"0 g","0 mg",0,"270 mg",12,"5 g",2,"3 g",11,"1 g","0 g",0,"1 g","","0 mcg",0,"0 mg",0,"0.4 mg",2,"280 mg",6,"HASS AVOCADOS, DISTILLED VINEGAR, CONTAINS 2% OR LESS OF WATER, JALAPENO PEPPERS, SALT, DEHYDRATED ONION, GRANULATED GARLIC."),
    createData('Crackers',"static/images/image6.png","static/json/image6.json","1 package (15g)","",80,"4 g",5,"1 g",5,"0 g","0 mg",0,"220 mg",10,"9 g",3,"1 g",4,"0 g","0 g",0,"1 g","","0 mcg",0,"8 mg",0,"0 mg",0,"28 mg",0,''),    
    createData('Fruit Juice',"static/images/image8.JPEG","static/json/image8.json","1 bottle","",270,"0 g",0,"0 g",0,"0 g","0 mg",0,"5 mg",0,"52 g",19,"5 g",17,"38 g","0 g",0,"2 g","","0 mcg",0,"40 mg",2,"0.9 mg",4,"920 mg",20,"APPLE JUICE FROM CONCENTRATE (WATER, APPLE JUICE CONCENTRATE), BANANA PUREE, STRAWBERRY PUREE, ORANGE JUICE, NATURAL FLAVOR, FRUIT JUICES FOR COLOR"),
    createData("Energy Drink","static/images/image4.png","static/json/image4.json","1 bottle","",180,"0 g",0,"",null,"","",null,"50 mg",2,"45 g",16,"",null,"44 g","42 g",84,"0 g","","",null,"",null,"",null,"880 mg",20,""),
    createData('Potato Chips',"static/images/image9.jpg","static/json/image9.json","about 13 chips (28g)",7,130,"4 g",5,"0 g",0,"0 g","0 mg",0,"200 mg",9,"20 g",7,"1 g",4,"1 g","",null,"3 g","","0 mcg",0,"0 mg",0,"1.2 mg",6,"10 mg",0,"ENRICHED WHEAT FLOUR (WHEAT FLOUR, NIACIN, REDUCED IRON, THIAMIN MONONITRATE, RIBOFLAVIN, FOLIC ACID), SUNFLOWER OIL AND/OR CANOLA OIL, WHOLE WHEAT FLOUR, AND LESS THAN 2% OF THE FOLLOWING: SEA SALT, ORGANIC CANE SUGAR, OAT FIBER, YEAST, MALTED BARLEY FLOUR, ROSEMARY EXTRACT (ANTIOXIDANT), AND ASCORBIC ACID (ANTIOXIDANT)"),
    createData('Gummy Candy',"static/images/image10.jpg","static/json/image10.json",32,"2.5",100,"0 g",0,"0 g",0,"0 g","0 mg",0,"30 mg",1,"24 g",9,"0 g",0,"14 g","14 g",28,"1 g",null,"0 mcg",0,"12 mg",0,"0 mg",0,"1 mg",0),        
    createData('Ice Cream',"static/images/image11.jpg","static/json/image11.json","2/3 cup (140g)","3 servings per container","380|1130","23 g|68 g","29|87","11 g|34 g","55|170","1 g|2.5 g","100 mg|295 mg","33|98","160 mg|470 mg","7|20","38 g|114 g","14|41","0 g|1 g","0|4","32 g|95 g","26 g|79 g","52|158","6 g|17 g",null,"0.7 mcg|2 mcg","4|10","140 mg|430 mg","10|35","0.9 mg|2.6 mg","6|15","160 mg|470 mg","4|10","CORN SYRUP, SUGAR, GELATIN, MODIFIED FOOD STARCH (CORN), FUMARIC ACID, LACTIC ACID, CITRIC ACID, SODIUM CITRATE, CALCIUM LACTATE, SODIUM LACTATE, NATURAL AND ARTIFICIAL FLAVORS, TITANIUM DIOXIDE [COLOR], YELLOW 5, RED 40, YELLOW 6, BLUE 1"),
    createData('Chocolate Bar',"static/images/image12.png","static/json/image12.json","1 package (42g)",1,200,"12 g",15,"8 g",40,"0 g","5 mg",1,"10 mg",0,"27 g",10,"2 g",8,"19 g","18 g",37,"2 g","","0 mcg",0,"13 mg",0,"3 mg",15,"125 mg",2,"SUGAR; CHOCOLATE; WHEAT FLOUR; COCOA BUTTER; PALM KERNEL OIL; COCOA PROCESSED WITH ALKALI; MILK FAT; CONTAINS 2% OR LESS OF: LACTOSE (MILK); LECITHIN (SOY); PGPR; SALT; YEAST; VANILLIN, ARTIFICIAL FLAVOR; BAKING SODA; MILK"),
    createData('Skittles',"static/images/image13.png","static/json/image13.jpg","1 package","",250,"2.5 g",4,"2.5 g",13,"0 g","0 mg",0,"20 mg",1,"56 g",19,"0 g",0,"46 g","",null,"0 g",null,"",null,"0 mg",0,"0 mg",0,"",null)
  ]);
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAddSelected, setIsAddSelected] = React.useState(true);

  const inputFile = React.useRef(null)
  const downloadFileUrl = React.useRef(null)
  
  const handleDownloadButtonClick = () => {
    downloadFileUrl.current.href = imageDatas[selectedIndex].json_url;
    downloadFileUrl.current.click();
    console.log(imageDatas[selectedIndex].json_url);
  }

  const handleListItemClick = (event, index) => {
    // potentially use useEffect hook for this...
    setSelectedIndex(index); 
    console.log(JSON.stringify(imageDatas[selectedIndex]))
    setIsAddSelected(false) 
  };

  const handleAddItemClick = () => {
    setIsAddSelected(true);
    setSelectedIndex(0)
    inputFile.current.click();    
  };

  const handleBackArrowClick = () => {
    if (selectedIndex !== 0) {
      setSelectedIndex(selectedIndex - 1)
    }   
    else {
      setIsAddSelected(true)
    }
  }

  const handleForwardArrowClick = () => {
    if (isAddSelected) {
      setIsAddSelected(false)
    }
    else if (selectedIndex < imageDatas.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const onImageChange = (event) => {    
    if (event.target.files && event.target.files[0]) {      
      const url = URL.createObjectURL(event.target.files[0]);      
      //update docs.alliumai.com to use this instead of requests/axios
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxIiwibmFtZSI6IkFsbGl1bSBBSSBEZW1vIiwiaWF0IjoxNjY3NDkyMDc2fQ.6eW9pcu98sq93j8y736WyhpajliwDhDephhLZlTXgqs");
      
      var formdata = new FormData();
      formdata.append("file", event.target.files[0], event.target.files[0].name);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      setIsLoading(true);
      fetch("https://fastgroceryscanner.com/demo-api/images/process", requestOptions)
        .then(response => response.json())
        .then(result => {        
          imageDatas.unshift(createData(
            event.target.files[0].name, 
            url, 
            URL.createObjectURL(new Blob([JSON.stringify(result)])),
            result["Nutrition Label"]?.["Serv. Size"]?.["value"],                         
            result["Nutrition Label"]?.["Servings"]?.["value"],                         
            result["Nutrition Label"]?.["Calories"]?.["value"],                         
            result["Nutrition Label"]?.["Total Fat"]?.["value"],
            result["Nutrition Daily Values"]?.["Total Fat Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Saturated Fat"]?.["value"],
            result["Nutrition Daily Values"]?.["Saturated Fat Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Trans Fat"]?.["value"],
            result["Nutrition Label"]?.["Cholesterol"]?.["value"],
            result["Nutrition Daily Values"]?.["Cholesterol Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Sodium"]?.["value"],
            result["Nutrition Daily Values"]?.["Sodium Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Total Carb."]?.["value"],
            result["Nutrition Daily Values"]?.["Total Carb. Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Dietary Fiber"]?.["value"],
            result["Nutrition Daily Values"]?.["Dietary Fiber Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Total Sugars"]?.["value"],
            result["Nutrition Label"]?.["Added Sugars"]?.["value"],
            result["Nutrition Daily Values"]?.["Added Sugars Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Protein"]?.["value"],
            result["Nutrition Daily Values"]?.["Protein Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Vitamin D"]?.["value"],
            result["Nutrition Daily Values"]?.["Vitamin D Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Calcium"]?.["value"],
            result["Nutrition Daily Values"]?.["Calcium Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Iron"]?.["value"],
            result["Nutrition Daily Values"]?.["Iron Percent Daily Value"]?.["value"],
            result["Nutrition Label"]?.["Potassium"]?.["value"],
            result["Nutrition Daily Values"]?.["Potassium Percent Daily Value"]?.["value"],
            result["Ingredients"]
          ))
          setImageDatas(imageDatas);
          setSelectedIndex(0);                    
          setIsLoading(false);
          setIsAddSelected(false);
        })
        .catch(error => { 
          console.log('error', error) 
          setIsLoading(false)
        });
    }
  };

  return (
    <Container>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "block",
          pt: 48,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography
          sx={{
            mx: "auto",
            mt: 1,
            bgcolor: "teal",
            opacity: 0.82,
            width: "38%",
            borderRadius: 3,
          }}
        >
          Extracting image data. Please be patient this can take up 1
          minute...
        </Typography>
      </Backdrop>
      <Container style={{ display: "none" }}>
        <input
          type="file"
          onChange={onImageChange}
          ref={inputFile}
          accept=".png,.jpg,.jpeg,.tif,.tiff,.pdf,.webp"
        />
        <a
          href="nutrition_info.json"
          ref={downloadFileUrl}
          download="nutrition_info.json"
        ></a>
      </Container>
      <Container
        sx={{
          display: "flex",
          mt: 2,
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
            lg: "row",
          },
        }}
        component={Paper}
      >
        <Container
          sx={{
            width: 100,
            justifyContent: "end",
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
          }}
        >
          <ArrowBack
            sx={{
              width: 100,
              height: "100%",
              cursor: "pointer",
              color: "grey",
              "&:hover": { bgcolor: "lightgrey", opacity: 0.5 },
              ...(isAddSelected && {
                color: "lightgray",
                "&:hover": {
                  bgcolor: "inherit",
                  opacity: "inherit",
                  cursor: "inherit",
                },
              }),
            }}
            onClick={(event) => {
              handleBackArrowClick();
            }}
            disabled={selectedIndex === 0}
          ></ArrowBack>
        </Container>
        <TableContainer sx={{ maxWidth: { l: "42%" }, mt: 5 }}>
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  {isAddSelected ||
                  !imageDatas[selectedIndex].servings_per_container
                    ? ""
                    : `${imageDatas[selectedIndex].servings_per_container} servings per container`}
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Serving size</TableCell>
                <TableCell align="right">
                  {isAddSelected ? "" : imageDatas[selectedIndex].serving_size}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Value</TableCell>
                <TableCell align="center">% DV</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Calories</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].calories_val}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Total Fat</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].total_fat_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].total_fat_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].total_fat_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Saturated Fat</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].sat_fat_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected || imageDatas[selectedIndex].sat_fat_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].sat_fat_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Trans Fat</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].trans_fat_val}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Cholesterol</TableCell>
                <TableCell align="center">
                  {isAddSelected
                    ? ""
                    : imageDatas[selectedIndex].cholesterol_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].cholesterol_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].cholesterol_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Sodium</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].sodium_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected || imageDatas[selectedIndex].sodium_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].sodium_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Total Carbohydrate</TableCell>
                <TableCell align="center">
                  {isAddSelected
                    ? ""
                    : imageDatas[selectedIndex].total_carbohydrate_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].total_carbohydrate_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].total_carbohydrate_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Dietary Fiber</TableCell>
                <TableCell align="center">
                  {isAddSelected
                    ? ""
                    : imageDatas[selectedIndex].dietary_fiber_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].dietary_fiber_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].dietary_fiber_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Total Sugars</TableCell>
                <TableCell align="center">
                  {isAddSelected
                    ? ""
                    : imageDatas[selectedIndex].total_sugars_val}
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Added Sugars</TableCell>
                <TableCell align="center">
                  {isAddSelected
                    ? ""
                    : imageDatas[selectedIndex].added_sugars_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].added_sugars_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].added_sugars_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Protein</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].protein_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected || imageDatas[selectedIndex].protein_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].protein_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Vitamin D</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].vitamin_d_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].vitamin_d_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].vitamin_d_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Calcium</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].calcium_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected || imageDatas[selectedIndex].calcium_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].calcium_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Iron</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].iron_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected || imageDatas[selectedIndex].iron_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].iron_dv}%`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Potassium</TableCell>
                <TableCell align="center">
                  {isAddSelected ? "" : imageDatas[selectedIndex].potassium_val}
                </TableCell>
                <TableCell align="center">
                  {isAddSelected ||
                  imageDatas[selectedIndex].potassium_dv == null
                    ? ""
                    : `${imageDatas[selectedIndex].potassium_dv}%`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {(!isAddSelected && imageDatas[selectedIndex].ingredients) &&
            <Box>
              <Box sx={{ fontWeight: 'bold' }}>
                Ingredients
              </Box>
              <Box sx={{ 'textAlign': 'left' }}>
                {isAddSelected ? "" : imageDatas[selectedIndex].ingredients}
              </Box>
            </Box>
          }
          <Container>
            <Button
              onClick={(event) => handleDownloadButtonClick(event)}
              sx={{
                color: theme.palette.primary,
                my: 1,
                ...(isAddSelected && {
                  display: "none",
                }),
              }}
            >
              Download Full Data
            </Button>
          </Container>
        </TableContainer>
        <Container>
          <Avatar
            src={imageDatas[selectedIndex].url}
            variant="square"
            sx={{
              width: "100%",
              height: 510,
              py: 6,
              ...(isAddSelected && {
                display: "none",
              }),
            }}
          />
          <Container
            sx={{
              cursor: "pointer",
              // height: 606,
              ...(!isAddSelected && {
                display: "none",
              }),
            }}
            onClick={(event) => handleAddItemClick(event)}
          >
            <AddIcon
              sx={{
                width: { xs: 220, sm: 300, md: 300, lg: 300 },
                height: { xs: 220, sm: 300, md: 300, lg: 300 },
                mt: { xs: 2, sm: 4, md: 14, lg: 14 },
                color: "grey",
              }}
            ></AddIcon>
            <Container>
              <Typography
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "inline",
                    lg: "inline",
                  },
                }}
              >
                Click{" "}
              </Typography>
              <Typography
                sx={{
                  display: {
                    xs: "inline",
                    sm: "inline",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                Tap{" "}
              </Typography>
              <Typography display={"inline"}>
                to select an image to scan or select an image below
              </Typography>
            </Container>
          </Container>
        </Container>
        <Container
          sx={{
            width: 100,
            px: 0,
            display: {
              xs: "none",
              sm: "none",
              md: "inline-block",
            },
          }}
        >
          <ArrowForward
            sx={{
              width: 100,
              height: "100%",
              cursor: "pointer",
              color: "grey",
              "&:hover": {
                bgcolor: "lightgrey",
                opacity: 0.5,
                boxshadow: "2px 2px",
              },
              ...(selectedIndex === imageDatas.length - 1 && {
                color: "lightgray",
                "&:hover": {
                  bgcolor: "inherit",
                  opacity: "inherit",
                  cursor: "inherit",
                },
              }),
            }}
            onClick={() => {
              handleForwardArrowClick();
            }}
          ></ArrowForward>
        </Container>
      </Container>
      <Typography>
        Selected item name:{" "}
        {isAddSelected
          ? "Please select a file"
          : imageDatas[selectedIndex].name}
      </Typography>
      <ImageList
        sx={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "95%",
          justifyContent: "center",
        }}
      >
        <ImageListItem sx={{ margin: 2 }}>
          <AddIcon
            sx={{
              width: 220,
              height: 220,
              color: "grey",
              cursor: "pointer",
              border: 3,
              borderColor: "transparent",
              "&:hover": {
                opacity: 0.5,
              },
              ...(isAddSelected && {
                borderColor: "orangered",
                borderStyle: "dotted",
              }),
            }}
            onClick={(event) => handleAddItemClick(event)}
          ></AddIcon>
        </ImageListItem>
        {imageDatas.map((data, index) => (
          <ImageListItem
            key={data.url}
            sx={{ width: 220, height: 220, margin: 2, display: "flex" }}
          >
            <Avatar
              loading="lazy"
              variant="square"
              onClick={(event) => handleListItemClick(event, index)}
              src={`${data.url}`}
              sx={{
                width: 220,
                height: 220,
                border: 3,
                borderColor: "transparent",
                "&:hover": {
                  cursor: "pointer",
                  opacity: 0.5,
                },
                ...(!isAddSelected &&
                  selectedIndex === index && {
                    borderColor: "orangered",
                    borderStyle: "dotted",
                  }),
              }}
            ></Avatar>
          </ImageListItem>
        ))}
      </ImageList>
      <Typography my={8}>
        The AlliumAI Nutrition Facts Extraction API (NuFex) uses
        state-of-the-art artificial intelligence to extract complete, structured
        nutritional information from product imagery in under a minute. Our AI
        works with ecommerce photos, smartphone pictures, packaging flats and
        more in a broad range of lighting scenarios, image qualities, and image
        angles. Supported image types include: png, jpeg, pdf, tiff. This
        structured information can be used for customer-centric shopping
        experiences, deeper customer analytics, and regulatory compliance.
      </Typography>
      <Typography mb={32}>
        Sign up{" "}
        <Link href="https://api.alliumai.com" target="_blank" rel="noopener">
          here
        </Link>{" "}
        to start creating accurate, complete data faster than ever before.
      </Typography>
    </Container>
  );
}
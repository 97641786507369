import logo from './logo.svg';
import * as React from 'react';
import './App.css';
import DemoGallery from './components/DemoGallery/DemoGallery';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import HeartIcon from '@mui/icons-material/Favorite';
import { Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {  
  // const pages = ['Products', 'Pricing', 'Blog'];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: '#008080',
      },
      secondary: {
        main: '#edf2ff',
      },
      warning: {
        main: '#FF4500'
      }
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppBar position="sticky" bgcolor={theme.palette.primary}>
          <Container>
            <Toolbar disableGutters>            
              <Link href="https://alliumai.com" target="_blank" rel="noopener"><Avatar src="static/images/allium_logo.png" sx={{ width: 100 }} variant="square" /></Link>
              <Typography sx={{ marginLeft: "auto", fontWeight: "bold" }} variant="h5">Nutrition Facts Extraction</Typography>
              <Link sx={{ ml: "auto" }} href="https://docs.alliumai.com" target="_blank" rel="noopener"><Avatar src="static/images/nufex_logo.png" sx={{ width: 100 }} variant="square" /></Link>
            </Toolbar>
          </Container>
        </AppBar>
        <DemoGallery />
        <AppBar position="static" bgcolor={theme.palette.primary}>
          <Container sx={{ pt: 2, height: "48px", display: "flex", justifyContent: "center"}}>
            <Typography mt={0.15} fontSize={10}>           
              { "Made with" }
            </Typography>      
            <HeartIcon sx={{ color: "red", mx: 0.25, fontSize: 16 }}></HeartIcon>   
            <Typography mt={0.15} fontSize={10}>           
              { "by the " }
              <Link href="" sx={{ "color": "white", textDecoration: "underline" }}>{ "AlliumAI" }</Link>
              { " team" }
            </Typography>    
          </Container>  
        </AppBar>
      </ThemeProvider>
    </div>
  );
}

export default App;
